::-webkit-scrollbar {
  width: 0px;
}

.content {

  background-color: rgba(255,255,255,0)!important;

}


@media (min-width: 671px) {
    .flexContainer {
      display: flex;
      overflow-x: scroll;
      width: 100%;
      justify-content: space-evenly;
    }
}

@media (max-width: 671px) {
    .flexContainer {
      display: flex;
      overflow-x: scroll;
      width: 100%;
      
    }
}

.downArrow{
  
  position: fixed;
  bottom: 1%;
  left: calc(50% - 50px);
}
.bounce {
  -moz-animation: bounce 2s infinite;
  -webkit-animation: bounce 2s infinite;
  animation: bounce 2s infinite;
}


.wipe-top{
  width:100%;
  height:100%;
  display:absolute;
}
.wipe-bottom{
  width:100%;
  height:100%;
  display:absolute;
  background-color:rgba(255,255,255,1); /* for cover the fix video below*/
  
}
.XAR-show2{
  width:100%;
  height:auto;
  
  background-color: #FFFFFF;
  background-size: cover;
  overflow: hidden;
  
  
}
.wipe-wrap{
  display: inline-block;
  overflow: hidden;
  
  width: 30%;

}
@media (max-width: 1100px){ /*the original width of the img*/
  .wipe-wrap{
    
    
    background-size: cover;

    overflow: hidden;
    
  }
  
}


.wipe-wrap .wipe1 {
  position: absolute;
  
  min-height:100%;
 
 
}
.wipe-wrap .wipe2-scroll-down {

  position: absolute;  
  overflow: hidden;
  clip-path: inset(0px 0px 0px 0px);/*top right bottom left*/
}

.wipe-wrap .wipe2-scroll-up {
  
  position: absolute;
  overflow: hidden;
  clip-path: inset(0px 0px 0px 0px);/*top right bottom left*/
}


.wipe-img{
  
  max-width:none;
}




@media (min-width: 768px) {
  .services-section .container {
    margin-top: -200px; } }


  @media (min-width: 576px) {
    .container {
      max-width: 540px; } 
  }
  @media (min-width: 768px) {
    .container {
      max-width: 720px; } 
  }
  @media (min-width: 992px) {
    .container {
      max-width: 960px; } 
  }
  @media (min-width: 1200px) {
    .container {
      max-width: 1140px; } 
  }


.service-content {
  
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 25vh;
  background-attachment: fixed;
  min-height: 100vh;
  background-size: 75%;
  background-position: center center;
  
  
}

.service-content .container {
  max-width: 80%;
  
  margin-right: auto;
  margin-left: auto;
}
.service-content .left-text h4 {
  font-size: 32px;
  font-weight: 500;
  color: #ff82af;/*adadad;*/
}
.service-content .left-text .line-dec {
  
  width: 45px;
  height: 3px;
  background-color: #ff7d27;
  margin: 20px 0px 20px 0px;
}

.service-content .left-text p {
  color: #000;
}

.service-content .left-text ul {
  padding: 0;
  margin-top: 15px;
  list-style: none;
  width:80%;
}

.service-content .left-text ul li {
  margin: 15px 0px;
  font-weight: 400;
  color: #555;/*#848484;/*#fff;*/
}

.service-content .left-text .primary-button {
  margin: 30px 0px;
}

.service-content .service-item {
  background-color: rgba(85, 85, 85, 0.7);
  padding: 25px 30px;
  text-align: center;
  color: #fff;
  margin-bottom: 30px;
  height: 100%;
}

.service-content .service-item h4{
  color: #ffffff;
}
.service-content .service-item .line-dec {
  width: 45px;
  height: 3px;
  background-color: #ff7d27;
  margin: 20px auto 15px auto;
}




/*----------------*/
.baner-content {
  padding-top: 50vh;
  text-align: center;
  
  padding-bottom: 10vh;
  background-size: auto 50%;
  
}

.baner-content h1 {
  margin-top: 0px;
  font-size: 32px;/*128px;*/
  color: #ff82af;
  font-weight: 500;
  text-transform: uppercase;
  margin-bottom: 0px;
  
  
}

.baner-content em {
  color: #555;/*#ff7d27;*/
  font-weight: 600;
  font-style: normal;
}

.baner-content span {
  display: inline-block;
  /*margin-top: 38px;*/
  font-weight: 300;
  font-size: 24px;/*68px;*/
  color: #252d4d;
  
  /*line-height: 128px;*/
}

.baner-content .primary-button {
  margin-top: 15px;
}

#operation-script{
  
  margin-left:60%;
  background-color: rgba(85,85,85,0.7);
  color:#FAFAFA;
  font-size:16px;
  margin-right:-15px;
  padding-right:15px;
  padding-left:5px;
  text-align:left;
  font-weight:400;
}

/*----------------------*/
.video-background-fix{  
  content: "";
  display: none;/*block;*/
  position: fixed;
      
  left: -40%;

  top: 0;
  width:auto;
  height: 100%;
  z-index: -10;

  background-size:  auto 100%;
  background-position: center center;
}

.first-content{
  
  margin-left: calc(12% + 15px);
  text-align: left;
  max-width: 70%;
  
}
.first-content h1{

  font-family: inherit;
  line-height: 1.5;
}
.first-content span{
  line-height: 1.5;
}

.second-content{

    padding-top:20%;
    padding-bottom:5%;
    text-align: right;
  padding-left: 15%;
}

/*===================================*/

.video-top{
  width:100%;
  height:100%;
  display:absolute;
}
.video-bottom{
  width:100%;
  height:100%;
  display:absolute;
}


.video-wrap{  
  
  position: relative;
  
  height: 2500px;/*5400 relative to the device height, because scroll is depend on the device height=> the smaller device requires small value of this setting to the same frames*/
  overflow: hidden;
 
  background-position: center; 
  
}

.video_block{
  position: absolute; 
  left: 0px; 
 
}

.video-caption{
  color: #ff82af;
  position: absolute;
  left:20%;
  margin-left:30px;
    margin-top: 0px;
    margin-bottom: 0px;
    /*max-width:40%;*/
  
}
.video-caption h2 {
  line-height: 1.5;
  font-size: 32px;
  font-weight: 500;
  text-transform: uppercase;
   color: #ff82af;
}



.video-caption p {
  
  font-size: 24px;
  line-height: 1.5;
  
  color: #555;/*#ff7d27;*/
  font-weight: 600;
  padding-top:15px;
  font-style: normal;
  line-height: 1.5;
    
}

/*------------------------*/

/* Feature Area css
============================================================================================ */
.feature_area {
  padding-top: 35px;
  padding-bottom: 30vh;
  background-color: rgba(0,0,0,0)!important;
  /*background-image: url('/vismile/AI-virtual-fitting/3davatar.jpg'); can not find /vismile folder and don't know why */ 
    
}
.feature_area .container {
  max-width: none;
  padding-left:10%;
  padding-right:10%;
  background-color: rgba(0,0,0,0)!important;
}
.feature_area .feature_inner .feature_item {
  margin-bottom: 50px;
  padding: 15px 0px 20px 0px;
}

/* Main title css
============================================================================================ */
.c_title {

  text-align: center;
  padding-bottom: 40px;
  /*padding-top:25px;*/
  margin-top:-15px;
  background-color: rgba(255,255,255,0.7);
}
.c_title .strong-heading:after {
    position: absolute;
    bottom: -6px;
    left: 0;
    width: 95px;
    height: 4px;
    content: '';
    background: #FFC300;
  
  margin-left: calc(50% - 50px);
  margin-right:auto;
  
}

.c_title img {
  padding-bottom: 10px;
}

.c_title h6 {
  font-size: 24px;
  font-weight: 600;/*bold;*/
  text-transform: uppercase;
  letter-spacing:5px;
  color: #555;/*#404040;/*#7c8d93;*/
  padding-bottom: 10px;
}
.c_title h5 {
  font-size: 24px;
  font-weight: 300;/*bold;*/
  
  color: #555;/*#404040;/*#7c8d93;*/
  
}
.c_title h2 {
  font-size: 24px;
  color: #555;/*#0b1033;*/
  font-weight: 300;
}
.c_title h3 {
  font-size: 24px;
  color: #555;/*#0b1033;*/
  font-weight: 300;
}

/*===================================*/
.parallax-content {
  
  background-color: rgba(0,0,0,0)!important;
  width: 100%;
  height: 100%;
  min-height: 30vh;
  background-size: cover;
  
  overflow:hidden;
}

.parallax-video {

    position:absolute;
    width: 100%;
    height: 100%;
    min-height: 30vh;
    margin-left:-20%;
    
}

.row-flex {
  display: flex;
  flex-wrap: wrap;
}

/*==============================*/
.wipe-caption{
  
  position: absolute;
  top:105px;
  left:3%;
  
  color: #555;/*0b1033#ff4ea7;/*#ff7d27;#ff82af;#1b2950; 3b3f73;*/
  
  font-size:48px;
  font-weight: 500;
  line-height: 1.5;
    
  
}
.wipe-caption h3{
  
  font-size:24px;
  font-weight:600;
  line-height: 1.5;
  margin: 0;
}

/*==============================================================================*/
/* responseive */
@media (max-width: 1750px){


}
@media (max-width: 1520px){


}
@media (max-width: 1300px){


}
@media (max-width: 1230px){


}
@media (max-width: 1199px){

  .video-caption{

    position: absolute;
    left:12%;
    margin-top: 0px;
    margin-bottom: 0px;
    width:21%;    
  }
  .video-caption p {
    padding-top:15px;
  }

}
@media (max-width: 1115px){
  

  .wipe-caption{
    position: absolute;
    
    top:90px;
    
    
    color: #555;/*ff4ea7#ff7d27;#ff82af;#1b2950; 3b3f73;*/
    font-family: "Roboto", 'Noto Sans TC', sans-serif;
      
    font-size:32px;
    font-weight: 500;
    line-height: 48px;
    /*text-align:center;*/
  }

}
@media (max-width: 991px){
  
  .video-caption{
    width:52%;
    position: absolute;
    left:5%;
    color: #ff7d27;/*#ff82af;#1b2950; 3b3f73;*/
    font-family: "Roboto", 'Noto Sans TC', sans-serif;
     
    font-size:48px;
    font-weight: 500;
    line-height: 48px;
    
  } 
  .service-content .container {
    padding-right: 3px;
    padding-left: 3px; 
    margin-right: auto; 
    margin-left: auto; 
    max-width:98%;
  }
  .first-content{
    margin-left: calc(5% + 12px);
    
  } 
  
}
@media (max-width: 767px){
 
    
}
@media (max-width: 575px){
  
  .first-content{  
    margin-left: 0;
  
  }
  .first-content h1{
    font-size: 32px;
     
  }  
  .video-caption{
    
    left:0;
    margin-left:18px;
  } 
  #operation-script{
  
    margin-left:30%;
    padding-left:8px;
    font-size:18px;
    line-height: 1.5;
  }   
}


