  .btnIcon {
    
    width: 100%;
    align-content: center;
    display: block ruby;

    padding-left: 33px !important;
    padding-right: 10% !important;

    text-align: initial;
    

  }