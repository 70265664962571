
.categories-wrapper {
  /*border-top: 4px solid black;*/
  justify-content: center;
  text-align: center;
}

.card {
  height: 30em;
  width: 20em;
  font-family: 'Quicksand', sans-serif;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  margin: .5em;
  padding: 1em;
  background-color: white;
  transition: transform 300ms bounce;
}

.items {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 100vw;
}

.items-title {
  text-align: center;
 
}
/*
a {
  color: black;
}

img {
  height: 20em;
}
*/
#product-name {
  border-top: 1px solid lightgrey;
  padding: 1em 0 0;
  font-size: 1.5em;
  font-family: 'Lato', sans-serif;
  margin: .5em 0;
}

#product-description {
  font-size: 1em;
  font-family: 'Lato', sans-serif;
  color: grey;
  font-weight: lighter;
  margin: 0;
}

#product-price {
  font-size: 1em;
}

.price-add {
  display: flex;
  justify-content: space-between;
}

i {
  color: lightgrey;
  transition: 0.5s;
}

i:hover {
  color: grey;
  transition: 0.5s;
  cursor: pointer;
}

.items-title {
  margin: 3em 0;
  font-family: 'Lato', sans-serif;
  font-weight: lighter;
  color: rgba(0, 0, 0, 0.8);
  text-transform: uppercase;
}

@media only screen and (min-width: 1200px){
  /*no use, will be replace by default*/
  .ui.container{
    width: 65%;

  }
  .items-title {
    text-align: center;
    font-size: -webkit-xxx-large;
  }



}
