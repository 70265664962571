.react-html5-camera-photo {
  /*position: relative;*/
  position: fixed;
  text-align: center;
  perspective: 2048px;
  perspective-origin: 540px;
}

.react-html5-camera-photo > video {
  width: 768px;
}

.react-html5-camera-photo > img {
  width: 768px;
}

.react-html5-camera-photo > .display-error {
  width: 768px;
  margin: 0 auto;
}

@media(max-width:768px){
  .react-html5-camera-photo > video, .react-html5-camera-photo > img {
    /*width: 100%;*/
    width: 100vW;
  }
  .react-html5-camera-photo > .display-error {
    /*width: 100%;*/
    width: 100vW;
  }
}

/* fullscreen enable by props */
.react-html5-camera-photo-fullscreen > video, .react-html5-camera-photo-fullscreen > img {
  /*width: 100vw;
  height:100vh;*/
  width: 100vh;
  height:100vw;
}
.react-html5-camera-photo-fullscreen > video {
  object-fit: fill;
}
.react-html5-camera-photo-fullscreen > .display-error {
  /*width: 100vw;
  height:100vh;*/
  width: 100vh;
  height:100vw;
}
