@import url("https://fonts.googleapis.com/css?family=Roboto:300,300i,400,400i,500,500i,700,900");
@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700");
@import url("https://fonts.googleapis.com/css?family=Noto+Sans+TC:300,300i,400,400i,600,600i,700");

/* can not find /fonts/ folder and don't know why
@font-face {
  font-family: 'icomoon';
  src: url("/fonts/icomoon/icomoon.eot?srf3rx");
  src: url('/fonts/icomoon/icomoon.eot?srf3rx#iefix') format("embedded-opentype"), 
       url('/fonts/icomoon/icomoon.ttf?srf3rx') format("truetype"), 
       url('/fonts/icomoon/icomoon.woff?srf3rx') format("woff"), 
       url('/fonts/icomoon/icomoon.svg?srf3rx#icomoon') format("svg");
  font-weight: normal;
  font-style: normal; 
 }
*/
/*
@font-face {
  font-family: 'Roboto';
  src: url("https://fonts.googleapis.com/css?family=Roboto:300,300i,400,400i,500,500i,700,900");
  font-weight: normal;
  font-style: normal; 
 }
@font-face {
  font-family: 'Open+Sans';
  src: url("https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700");
  font-weight: normal;
  font-style: normal; 
 }
@font-face {
  font-family: 'Noto+Sans+TC';
  src: url("https://fonts.googleapis.com/css?family=Noto+Sans+TC:300,300i,400,400i,600,600i,700");
  font-weight: normal;
  font-style: normal; 
 }  
 */
/*@src/components/App/index.css*/
.content {
  display: flex;
  margin: 0;
  width: 100%;
  justify-content: center;/*space-between;*//*center*/
  /*min-height: 82vh;*/
}

.app {
  min-height: calc(100vh - 60px);
}

