/*@src/components/BaseLayout/Footer/index.css*/
.footer {
  /*height: 3em;*/
  /*background-color: #c5c5c5; for Havas project*/
  background-color: #000000;
  color: #FFF;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Quicksand', sans-serif;
  font-weight: lighter;

  /*position:absolute;
  left:0;
  bottom:0;
  right:0;
  padding: 15px;
*/
}



.footerImg {
  width: 50% !important;
  display: grid;

}
/*  Form.css  */
* {box-sizing: border-box;}

.contact-form {
  max-width: 510px;
  margin: 30px auto;
}

.contact-form__container {
  /*border: 1px solid #f5f5f5;*/
  /*padding: 35px;*/
  /*box-shadow: 2px 2px 10px 0 #f5f5f5;*/
}
.contact-form__container p{
  margin: 0;
  text-align: left;
}
.contact-form__header h2{
  color: #4a4a4a;
}
.contact-form__header p{
  margin-bottom: 0;
  color: #4a4a4a;
  color: #311c56;
}
.contact-form__header{
  background: #f7f7f7;
  padding: 2px 35px 31px;
  margin: 0;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
p {
  /*text-align: center;*/
  font-weight: 500;
}

.fieldsetForm{

  border: none;

}

@media (min-width: 992px) {
  #name{
    width: 50%;
  }
  #email{
    width: 50%;
  } 

  #footerGrid {
  
    width: 100vw !important;
    justify-content: space-evenly;
    margin: 3px;
    background-color: rgba(255,255,255,1);
  }
}

@media (max-width: 992px) {
  #name{
    width: 100%;
  }
  #email{
    width: 100%;
  } 
  #footerGrid {
  
    width: 100vw !important;
    justify-content: space-evenly;
    margin: 3px;
    background-color: rgba(255,255,255,1);
  }
}




#subject{
    width: 100%;

}

#msg{

    width: 100%;

}

.input-group:focus-within input {
    font-size: 18px;
    color: rgb(85, 85, 85);
    
}


input[type=text],input[type=email], select, textarea {
    width: 100%;
    padding: 12px;
    
    
    border: none;
    border-bottom: 2px solid #e1e1e1;
    padding-left: 0px;
    height: 50px;
    color: #a5bbd0;

    border-radius: 4px;
    box-sizing: border-box;
    margin-top: 6px;
    margin-bottom: 16px;
    resize: vertical;
}
input[type=subject], select, textarea {
    width: 100%;
    padding: 12px;
    
    
    border: none;
    border-bottom: 2px solid #e1e1e1;
    padding-left: 0px;
    height: 50px;
    color: #a5bbd0;

    border-radius: 4px;
    box-sizing: border-box;
    margin-top: 6px;
    margin-bottom: 16px;
    resize: vertical;
}

input[type=submit] {
    background-color: #311c56;
    color: white;
    padding: 12px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    width: 100%;
}
textarea{
  height: 140px;
}
input[type=submit]:hover {
    background-color: #140c23;
}

.container {
    border-radius: 5px;
    background-color: #f2f2f2;
    padding: 20px;
}
.sucsess  {
  margin: 20px 0;
  /*border: 1px solid green;*/
  padding: 7px;
  font-weight: bold;

  width: 60%;
  margin-left: auto;
}
.sucsess h1 {
  font-size: 24px;
  font-weight: 600;
  color: #97ccfe;
}
.sucsess h2 {
  font-size: 16px;

}
.error {
  margin: 20px 0;
  border: 1px solid red;
  padding: 7px;
  font-weight: bold;
}


.l_title {
  padding-bottom: 55px;
 
}



.strong-heading {
  font-size: 24px;
  margin-bottom: 2em;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 5px;
  line-height: 1.8;
  position: relative; }
  .strong-heading:after {
    position: absolute;
    bottom: -6px;
    left: 0;
    width: 60px;
    height: 4px;
    content: '';
    background: #eac15a;/*#FFC300;*/ }
  .strong-heading span {
    display: block; }
  @media screen and (max-width: 768px) {
    .strong-heading {
      margin-bottom: 3em; } 
}


.f_about_widget {
    padding-right: 35px;
}
