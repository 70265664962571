

.circular-chart {
  display: block;
  margin: 10% auto;
  max-width: 80%;
  max-height: 120px;
}

.circle1 {
  stroke: #f4707d;
  fill: none;
  stroke-width: 2.4;
  stroke-linecap: round;
  animation: progress1 1.9s ease-out infinite;
  transform-origin: center;
  
  /*
   stroke-dasharray: 0 100;
  transform: rotate(0deg) ;
  
   stroke-dasharray: 30 70;
  transform: rotate(10deg) ;
  
     stroke-dasharray: 50 50;
  transform: rotate(80deg) ;
  
  stroke-dasharray: 30 70;
  transform: rotate(210deg) ;
  
     stroke-dasharray: 10 90;
  transform: rotate(320deg) ;

  */
}
.circle2 {
  stroke: #72dbf3;
  fill: none;
  stroke-width: 2.4;
  stroke-linecap: round;
  animation: progress2 1.9s ease-out infinite;
  transform-origin: center;
  
  
  
 /*  
  stroke-dasharray: 80 0;
  transform: rotate(0deg) ;
  
  stroke-dasharray: 70 10;
  transform: rotate(-30deg) ;
  
  stroke-dasharray: 60 20;
  transform: rotate(-80deg) ;
  
  stroke-dasharray: 40 40;
  transform: rotate(-150deg) ;
  
  stroke-dasharray: 50 30;
  transform: rotate(-300deg) ;

  
  stroke-dasharray: 60 20;
  transform: rotate(-450deg) ;

  stroke-dasharray: 70 10;
  transform: rotate(-650deg) ;

  stroke-dasharray: 80 0;
  transform: rotate(-850deg) ;

  
  */
}
.circle3 {
  stroke: #72f6c2;
  fill: none;
  stroke-width: 2.4;
  stroke-linecap: round;
  animation: progress3 1.9s ease-out infinite;
  transform-origin: center;
  
 
  
  
  
  /*
  
  stroke-dasharray: 60 0;
  transform: rotate(-1500deg) ;
  
  stroke-dasharray: 50 10;
  transform: rotate(-170deg) ;
   
  
  stroke-dasharray: 40 20;
  transform: rotate(-210deg) ;
 
  stroke-dasharray: 30 30;
  transform: rotate(-300deg) ;
  
  stroke-dasharray: 40 20;
  transform: rotate(-420deg) ;

  stroke-dasharray: 45 15;
  transform: rotate(-580deg) ;

  stroke-dasharray: 50 10;
  transform: rotate(-660deg) ;

  stroke-dasharray: 60 00;
  transform: rotate(-720deg) ;
  
  */
}
@keyframes progress1 {
  0% {
        stroke-dasharray: 100 0;
  transform: rotate(0deg) ;
  

 
  }
  10% {
        stroke-dasharray: 100 0;
  transform: rotate(0deg) ;
  

 
  }
  
  20% {
    
     stroke-dasharray: 95 5;
  transform: rotate(40deg) ;

 
  }
  30% {
    
    
  stroke-dasharray: 90 10;
  transform: rotate(100deg) ;
  
 
 
  } 
  60% {
     stroke-dasharray: 82 18;
  transform: rotate(160deg) ;
  
 
  }
  
  70% {
   stroke-dasharray: 75 25;
  transform: rotate(220deg) ;

  

  }
  
  80% {
    
  stroke-dasharray: 60 40;
  transform: rotate(450deg) ;

 
    
  }
  90% {
     stroke-dasharray: 80 20;
  transform: rotate(650deg) ;

 
  }
  
  100% {
     stroke-dasharray: 100 0;
  transform: rotate(730deg) ;
    
  }
  
}
@keyframes progress2 {
  0% {
        stroke-dasharray: 80 0;
  transform: rotate(0deg) ;
  

 
  }
  10% {
        stroke-dasharray: 80 0;
  transform: rotate(0deg) ;
  

 
  }
  
  20% {
    
     stroke-dasharray: 70 10;
  transform: rotate(-30deg) ;

 
  }
  30% {
    
    
  stroke-dasharray: 60 20;
  transform: rotate(-80deg) ;
  
 
 
  } 
  60% {
     stroke-dasharray: 45 35;
  transform: rotate(-150deg) ;
  
 
  }
  
  70% {
   stroke-dasharray: 50 30;
  transform: rotate(-300deg) ;

  

  }
  
  80% {
    
  stroke-dasharray: 60 20;
  transform: rotate(-450deg) ;

 
    
  }
  90% {
     stroke-dasharray: 70 10;
  transform: rotate(-650deg) ;

 
  }
  
  100% {
     stroke-dasharray: 80 0;
  transform: rotate(-740deg) ;
    
  }
  
}
@keyframes progress3 {
   0% {
       stroke-dasharray: 60 0;
  transform: rotate(-150deg) ;
  

 
  }
  10% {
           stroke-dasharray: 60 0;
  transform: rotate(-150deg) ;
  

  

 
  }
  
  20% {
      stroke-dasharray: 50 10;
  transform: rotate(-170deg) ;
   
 
 
  }
  30% {
    
     
  stroke-dasharray: 40 20;
  transform: rotate(-210deg) ;
  
  
 
 
 
  } 
  60% {
  stroke-dasharray: 35 25;
  transform: rotate(-340deg) ;

  }
  
  70% {
    stroke-dasharray: 40 20;
  transform: rotate(-520deg) ;


  }
  
  80% {
  stroke-dasharray: 45 15;
  transform: rotate(-640deg) ;

    
  }
  90% {
    
    
  stroke-dasharray: 50 10;
  transform: rotate(-750deg) ;

 
  }
  
  100% {
  stroke-dasharray: 60 00;
  transform: rotate(-900deg) ;
 
  }
  
  
}