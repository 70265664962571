/*@src/components/BaseLayout/index.css*/
.content {
  background-color: #E0E1E2;/*#F9F6F6;*/
}

.firstHeader {
  /*background: url('/images/banner1080x230_01.jpg'); for Havas project
  background: url('/images/1_Magic_Mirror.jpg');*/
}

.secondHeader {
  /*background: url('/images/banner1080x230_01.jpg'); for Havas project
  background: url('/images/2_Sales.jpg');*/
}

.thirdHeader {
  /*background: url('/images/banner1080x230_01.jpg'); for Havas project
  background: url('/images/3_Female.jpg');*/
}

.fourthHeader {
  /*background: url('/images/banner1080x230_01.jpg'); for Havas project
  background: url('/images/4_Men.jpg');*/
}

.firstHeader, .secondHeader, .thirdHeader, .fourthHeader {
  display: flex;
  flex-direction: column;
  background-size: cover;
  background-position: left;
  /*height: 180px;*/
}

