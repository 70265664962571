.ui.secondary.menu .active.item {
    background: unset!important;
    font-weight: 700!important;
}

/*no working here*/
.ui.Dropdown.Menu.rightMenu:active.item {

    background: rgba(0,0,0,0)!important;
    
}
/*no working here*/
.ui.Dropdown.Menu.rightMenu.item:hover {

    background: rgba(0,0,0,.03)!important;
    color: rgba(0,0,0,.95)!important;
    
}