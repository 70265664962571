/*@src/components/Products/index.css*/

  .fluid {
    flex-direction: row;
  }

  .fluid__image-container{
    flex: 0 0 30%;
    margin: 20px 0 20px 20px;
  }

  .fluid__instructions {
    flex: 0 0 50%;
    padding-top: 30px;
  }

  .fixed__instructions {
    padding-top: 30px;
    margin: 0 10px;
  }

  .portal {

  }

.catalog-header {
  height: none;
}

.product-header {

  margin: auto;
  text-align: center;
}

.product-meta {

  margin: auto;

}
.similar-products {
  /*height: 100em;*//*70em*/
  width: 20em;
  overflow: scroll;
  font-family: 'Lato', sans-serif;
  margin: 0 3em;
  background-color: white;
}
.show-product {
  width: 100vw;
  display: flex;
  justify-content: center;
  border-top: 4px solid black;
}
.show-suit {
  width: 100vw;
  display: flex;
  justify-content: center;
  border-top: 4px solid black;
}
.recommend-products {
  justify-content: center;
}
.product-wrapper {
  /*display: flex;
  justify-content: center;
  align-items: center;*/
  /*margin-left: 20em;*/ /*portrait, no need this*/
}

/* added */
#videoId {
  min-width: 200px;
  min-width: 200px;
  background-color: black;
}

/*from top index.css originally*/
.items-wrapper {
  border-top: 4px solid black;
  
}
/*
.item {
  height: 30em;
  width: 20em;
  font-family: 'Quicksand', sans-serif;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  margin: .5em;
  padding: 1em;
  background-color: white;
  transition: transform 300ms bounce;
}

.items {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 100vw;
}
*/
.items-title {
  text-align: center;
}

a {
  color: black;
}
/*
img {
  height: 20em;
}
*/
#product-name {
  border-top: 1px solid lightgrey;
  padding: 1em 0 0;
  font-size: 1.5em;
  font-family: 'Lato', sans-serif;
  margin: .5em 0;
}

#product-description {
  font-size: 1em;
  font-family: 'Lato', sans-serif;
  color: grey;
  font-weight: lighter;
  margin: 0;
}

#product-price {
  font-size: 1em;
}

.price-add {
  display: flex;
  justify-content: space-between;
}
/*
i {
  color: lightgrey;
  transition: 0.5s;
}

i:hover {
  color: grey;
  transition: 0.5s;
  cursor: pointer;
}
*/
.items-title {
  margin: 3em 0;
  font-family: 'Lato', sans-serif;
  font-weight: lighter;
  color: rgba(0, 0, 0, 0.8);
  text-transform: uppercase;
}

.tryonButton{
    width : unset;
    height : unset; 
}

.tryonButtonImg{
      

}

@media only screen and (min-width: 1400px){
  /*no use, will be replace by default*/

  .textStyle2{
    font-size: 2.0vw!important;
    line-height: 3.5vw!important;

  }
  .textStyle3{
    font-size: 1.5vw;
    line-height: 3.5vw;

  }
  .textStyle4{
    font-size: 1.0vw;
    line-height: 3.5vw;

  }

  .tryonButton{
      width : 17vw;
      height: 120%;
      border-radius: 30em;
  }

  .tryonButtonImg{
      width: 15vw;
      margin: auto;

  }


}
