::-webkit-scrollbar {
  width: 0px;
}


@media (min-width: 671px) {
    .flexContainer {
      display: flex;
      overflow-x: scroll;
      width: 100%;
      justify-content: space-evenly;
    }
}

@media (max-width: 671px) {
    .flexContainer {
      display: flex;
      overflow-x: scroll;
      width: 100%;
      
    }
}


.cardImage2 {
  flex-shrink: 0;
  width: 300px;
  height: 200px;
  border-radius: 10px;
  margin-left: 10px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}